import React from "react";
import { useDescope } from "@descope/react-sdk";
import { Button } from "antd";
import styles from "./auth.module.scss";

function Logout() {
  const sdk = useDescope();

  const handleLogout = () => {
    sdk.logout();
  };

  return (
    <Button onClick={handleLogout} className={styles.logout}>
      Logout
    </Button>
  );
}

export default Logout;
