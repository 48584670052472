import React, { useState, useEffect } from "react";
import { paymentSystemItems } from "../NavBar/variables";
import styles from "./PaymentSystemIndicator.module.scss";
import { useParams } from "react-router-dom";

function PaymentSystemIndicator() {
  const [paymentSystemItemValue, setPaymentSystemItemValue] = useState(null);
  const { paymentSystem } = useParams();
  const currentUrl = window.location.href;

  useEffect(() => {
    let foundItem;

    if (paymentSystem) {
      foundItem = paymentSystemItems.find((item) =>
        paymentSystem.includes(item.value.toLowerCase())
      );
    } else {
      foundItem = paymentSystemItems.find((item) =>
        currentUrl.includes(item.value.toLowerCase())
      );
    }

    if (foundItem) {
      setPaymentSystemItemValue(foundItem.value);
    }
  }, [paymentSystem, currentUrl]);

  return (
    <>
      {paymentSystemItemValue && (
        <div className={styles.container}>
          <div className={styles.indicator}>
            {paymentSystemItemValue.toUpperCase()}
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentSystemIndicator;
