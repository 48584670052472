import React, { useContext, useEffect } from "react";
import ApiV1 from "../components/Content/TextContent/api/v1";
import SideMenuContext from "../context/SideMenuContext";
import MainLayout from "../layouts/MainLayout/MainLayout";

function ApiEndpoints() {
  const { currentPath, setOpenSection } = useContext(SideMenuContext);

  useEffect(() => {
    if (currentPath.includes("stripe")) {
      setOpenSection("5");
    } else if (currentPath.includes("braintree")) {
      setOpenSection("6");
    } else if (currentPath.includes("paypal")) {
      setOpenSection("7");
    }
  }, [currentPath, setOpenSection]);

  return <MainLayout ContentComponent={ApiV1} />;
}

export default ApiEndpoints;
