import React from "react";
import PlainText from "../CodeDisplay/PlainText";
import styles from "./TextContent.module.scss";

function FrontendIntegration() {
  return (
    <div className={styles.innerContentContainer}>
      {" "}
      <h2 className={styles.subTitle}>Using Bouncepay&apos;s JS library</h2>
      <div className={styles.textAndCodeContainer}>
        <div className={styles.textContainer}>
          <p>
            The merchant will need to load a JS library. The library is used to
            send additional meta data on the session, browser and device of the
            buyer.
          </p>
          <p>
            When a user session starts, or around that time, the library needs
            to be initialised.
          </p>
        </div>

        <div className={styles.codeContainer}>
          <PlainText bouncepay={true} type="init" titleText={"javascript"} />
        </div>
      </div>
      <div className={styles.textAndCodeContainer}>
        <div className={styles.textContainer}>
          <p>
            At the point of capturing the card details of the buyer, the
            merchant will need to call a library function that will match the
            session details with the card that was captured.
          </p>
        </div>

        <div className={styles.codeContainer}>
          <PlainText
            bouncepay={true}
            type="connect"
            parameters="connectorMethod, objectId"
            titleText={"javascript"}
          />
        </div>
      </div>
      <div className={styles.textAndCodeContainer}>
        <div className={styles.textContainer}>
          <p>
            connectorMethod - Is the type of the object that was created
            (payment_method, transaction etc.)
          </p>
          <p>
            objectId - Is the ID of the object that was created (payment method
            ID, transaction ID etc.)
            The supported objects will be agreed with the merchant as part of
            the onboarding.
          </p>
        </div>

        <div className={styles.codeContainer}>
          <PlainText
            bouncepay={true}
            type="connect"
            parameters="'transaction', 'dnajfxat'"
            titleText={"javascript"}
          />
        </div>
      </div>
      <h2 className={styles.subTitle}>Direct Integration</h2>
      <div className={styles.textAndCodeContainer}>
        <div className={styles.textContainer}>
          <p>
          </p>
          <p>Alternatively, the information required can be sent directly to Bounce by using POST to the transaction event URL <span className={styles.link}>[base_url]/transaction-event</span></p>
          <p>Where connector_method is the object that Bounce will use to match the event to a transaction. This would be a customer_id, a transaction_id etc.</p>
          <p></p>
          <p>Bounce then uses the IP of the request and the user_agent header to gather information on the session.</p>
          <p></p>
        </div>

        <div className={styles.codeContainer}>
          <PlainText bouncepay={false} />
        </div>
      </div>
      
    </div>
  );
}

export default FrontendIntegration;
