import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";
/* eslint-disable */

function JavaUnirset() {
  const { apiOrSandbox } = useContext(NavContext);
  return (
    <p>
      <span>{`Unirest.setTimeouts(0, 0);`}</span>
      <br />
      <span>{`HttpResponse<String> response = Unirest.post("https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/webhook/").asString();`}</span>
      <br />
    </p>
  );
}

export default JavaUnirset;
