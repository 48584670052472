import React from "react";
import PropTypes from "prop-types";
import styles from "./MainLayout.module.scss";
import NewMenu from "../../components/Menu/NewMenu";
import Logo from "../../components/Logo/Logo";
import PaymentSystemIndicator from "../../components/PaymentSystemIndicator/PaymentSystemIndicator";
import User from "../../components/User/User";

function MainLayout({ ContentComponent }) {
  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        <div>
          <Logo />
          <NewMenu />
        </div>
        <div className={styles.menuUserContainer}>
          <User />
        </div>
      </div>
      <div className={styles.content}>
        <PaymentSystemIndicator />
        <ContentComponent />
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  ContentComponent: PropTypes.elementType,
};

export default MainLayout;
