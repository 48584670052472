import React from "react";
import PropTypes from "prop-types";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import styles from "./CodeDisplay.module.scss";

function ExampleResponseBody({
  showResponse,
  showBody,
  successfulResponse,
  validationError,
  headers,
}) {
  return (
    <>
      {showBody ? (
        <>
          {showResponse ? (
            <JSONPretty
              style={{ backgroundColor: "none !important" }}
              id="json-pretty"
              data={successfulResponse}
            />
          ) : (
            <JSONPretty
              style={{ backgroundColor: "none !important" }}
              id="json-pretty"
              data={validationError}
            />
          )}
        </>
      ) : (
        <div>
          {headers?.length > 0 ? (
            <>
              {headers?.map((item, idx) => (
                <div key={idx} className={styles.headersContainer}>
                  <p className={styles.headersContainerKey}>{item?.key}</p>
                  <p>{item?.value}</p>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.noHeaders}>
              <p>No response headers</p>
              <p>This request does not return any response headers</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

ExampleResponseBody.propTypes = {
  showResponse: PropTypes.bool,
  showBody: PropTypes.bool,
  successfulResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headers: PropTypes.array,
  validationError: PropTypes.object,
};

export default ExampleResponseBody;
