export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const scrollToElement = (ref, scrollValue, setter, toggleScroll) => {
  if (ref.current && scrollValue) {
    ref.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
    toggleScroll(scrollValue, setter);
  }
};

export const handleEnvironment = (environment) => {
  return environment === "1" ? "api" : "sandbox";
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
