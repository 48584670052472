import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SideMenuContext from "../../../context/SideMenuContext";
import styles from "./TextContent.module.scss";
import { routes } from "../../../routes/routes";
import NavContext from "../../../context/NavContext";
import { paymentSystemItems } from "../../NavBar/variables";
import { paymentSystems } from "../../../utils/constants";

function Link({
  text,
  value,
  setter,
  endpoint,
  section,
  sectionKey,
  paymentSystemType,
}) {
  const { toggleScroll, setOpenSection } = useContext(SideMenuContext);
  const { paymentSystem, setPaymentSystem } = useContext(NavContext);
  const navigate = useNavigate();

  const handleOpenSection = () => {
    setOpenSection(sectionKey);
  };

  const handleClick = () => {
    if (paymentSystemType === paymentSystems.stripe) {
      setPaymentSystem(paymentSystemItems[0]);
      navigate(routes.apiEndpoints.endpoint(paymentSystemType, endpoint));
    } else {
      navigate(
        routes.apiEndpoints.endpoint(
          paymentSystem?.value.toLowerCase(),
          endpoint
        )
      );
    }
    toggleScroll(value, setter);
  };

  return (
    <span
      className={styles.link}
      onClick={!section ? handleClick : handleOpenSection}>
      {text}
    </span>
  );
}

Link.propTypes = {
  text: PropTypes.string,
  value: PropTypes.bool,
  setter: PropTypes.func,
  endpoint: PropTypes.string,
  section: PropTypes.bool,
  sectionKey: PropTypes.string,
  paymentSystemType: PropTypes.string,
};

export default Link;
