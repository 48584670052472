import React, { useContext } from "react";
import { Menu } from "antd";
import "./SideMenu.css";
import { items, sideMenuOnClick } from "./helperFunctions";
import { useNavigate } from "react-router-dom";
import SideMenuContext from "../../context/SideMenuContext";

const NewMenu = () => {
  const { openSection, setOpenSection } = useContext(SideMenuContext);
  let navigate = useNavigate();

  return (
    <Menu
      className="sideBar"
      selectedKeys={openSection}
      mode="inline"
      theme="dark"
      items={items}
      onClick={(item) => {
        setOpenSection(item?.key);
        sideMenuOnClick(item, navigate);
      }}
    />
  );
};
export default NewMenu;
