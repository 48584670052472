import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";
import Curl from "./Curl";
import JavaUnirset from "./JavaUnirset";
import NodeJsNative from "./NodeJsNative";
import Php from "./Php";
import PythonRequest from "./PythonRequest";
import Ruby from "./Ruby";

function TransactionGetRequests() {
  const { language } = useContext(NavContext);
  return (
    <div>
      {language?.key === "0" ? (
        <PythonRequest />
      ) : language?.key === "1" ? (
        <NodeJsNative />
      ) : language?.key === "2" ? (
        <Php />
      ) : language?.key === "3" ? (
        <JavaUnirset />
      ) : language?.key === "4" ? (
        <Curl />
      ) : language?.key === "5" ? (
        <Ruby />
      ) : null}
    </div>
  );
}

export default TransactionGetRequests;
