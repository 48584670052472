import React, { useEffect } from "react";
import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { handleEnvironment } from "../utils/helperFunctions";
import {
  enviornmentItems,
  languageItems,
  paymentSystemItems,
} from "../components/NavBar/variables";

const NavContext = createContext(null);

export function NavProvider({ children }) {
  const [environment, setEnvironment] = useState(enviornmentItems[1]);
  const [language, setLanguage] = useState(languageItems[0]);
  const [paymentSystem, setPaymentSystem] = useState(paymentSystemItems[0]);
  const [apiOrSandbox, setApiOrSandbox] = useState("api");
  const isStripe = paymentSystem?.key === "0";

  useEffect(() => {
    setApiOrSandbox(handleEnvironment(environment?.key));
  }, [environment]);

  const value = {
    environment,
    setEnvironment,
    language,
    setLanguage,
    apiOrSandbox,
    paymentSystem,
    setPaymentSystem,
    isStripe,
  };

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
}

NavProvider.propTypes = {
  children: PropTypes.node,
};
export default NavContext;
