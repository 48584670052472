export const paymentSystems = {
  stripe: "stripe",
  braintree: "braintree",
  paypal: "paypal",
  adyen: "adyen",
  bluesnap: "bluesnap",
};

export const paymentSystemSectionKey = {
  stripe: "5",
  braintree: "6",
  paypal: "7",
  adyen: "8",
  bluesnap: "9",
};

