import React from "react";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";

function Logo() {
  return (
    <Link to="/">
      <div className={styles.container}>
        <img
          src={"/images/bounce_logo_white.svg"}
          alt=""
          className={styles.logo}
        />
      </div>
    </Link>
  );
}

export default Logo;
