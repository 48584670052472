export const exampleCodeSelectOptions = [
  {
    label: "Successful Response",
    value: "Successful Response",
  },
  {
    label: "Validation Error",
    value: "Validation Error",
  },
];

// Transaction list

export const transactionListSuccessfulResponseStripe = [
  {
    status: "approved",
    process_status: "scheduled",
    is_bounce_retry: false,
    payment_system_transaction_id: "ch_3SdV3qaS83yFrt6T1rlSFoRs",
  },
  {
    status: "approved",
    process_status: "scheduled",
    is_bounce_retry: false,
    payment_system_transaction_id: "ch_3Kfw9fKYFcH0CnWC0Rm8Sues",
  },
];

export const transactionListSuccessfulResponseBraintree = [
  {
    status: "approved",
    process_status: "scheduled",
    is_bounce_retry: false,
    payment_system_transaction_id: "8t8xa63n",
  },
  {
    status: "approved",
    process_status: "scheduled",
    is_bounce_retry: false,
    payment_system_transaction_id: "sk4h845m",
  },
];

export const transactionListValidationError = {
  detail: [
    {
      loc: ["do nostrud", "exercitation in sed incididunt"],
      msg: "occaecat amet anim",
      type: "aliquip nulla",
    },
    {
      loc: ["magna et", "nisi do eiusmod ad"],
      msg: "sit aute reprehenderit non cupidatat",
      type: "qui cupidatat consec",
    },
  ],
};

export const transactionListHeaders = [
  {
    key: "Content-Type",
    value: "application/json",
  },
];

// Transaction GET

export const transactionGetSuccessfulResponseStipe = {
  status: "approved",
  process_status: "scheduled",
  is_bounce_retry: false,
  payment_system_transaction_id: "ch_3SdV3qaS83yFrt6T1rlSFoRs",
};

export const transactionGetSuccessfulResponseBraintree = {
  status: "approved",
  process_status: "scheduled",
  is_bounce_retry: false,
  payment_system_transaction_id: "8t8xa63n",
};

export const transactionGetValidationError = {
  detail: [
    {
      loc: ["velit ea", "Lorem aute ex"],
      msg: "dolore non in dolor",
      type: "do veniam cillum sed Excepteur",
    },
    {
      loc: ["est sunt consectetur sint", "nostrud consectetur quis"],
      msg: "est anim tempor",
      type: "ut al",
    },
  ],
};

export const transactionGetHeaders = [
  {
    key: "Content-Type",
    value: "application/json",
  },
];

// Transaction POST Decline

export const braintreeTransactionPostDeclineSuccessfulResponse = {
  decision_at: "2022-05-11T15:05:50.403323",
  status: "approved",
  payment_system_transaction_id: "dnajfxat",
};

export const stripeTransactionPostDeclineSuccessfulResponse = {
  decision_at: "2022-05-11T15:05:50.403323",
  status: "approved",
  payment_system_transaction_id: "ch_3OcAB4CS3h9RvET91coEWlA3",
};

export const transactionPostDeclineHeaders = [
  {
    key: "Date",
    value: "Mon, 28 Mar 2022 13:31:45 GMT",
  },
  {
    key: "Content-Type",
    value: "application/json",
  },
  {
    key: "Content-Length",
    value: "21",
  },
  {
    key: "Connection",
    value: "keep-alive",
  },
];

export const transactionPostDeclineSelectOptions = [
  {
    label: "Transaction Post Decline",
    value: "Transaction Post Decline",
  },
];

export const stripePostDeclineRawJson = {
    "charge": "ch_3OcAB4CS3h9RvET91coEWlA3",
    "code": "card_declined",
    "decline_code": "generic_decline",
    "doc_url": "https://stripe.com/docs/error-codes/card-declined",
    "message": "Your card was declined.",
    "param": null,
    "payment_intent": {
      "amount": 10000,
      "amount_capturable": 0,
      "amount_details": {
        "tip": {}
      },
      "amount_received": 0,
      "application": null,
      "application_fee_amount": null,
      "automatic_payment_methods": {
        "allow_redirects": "always",
        "enabled": true
      },
      "canceled_at": null,
      "cancellation_reason": null,
      "capture_method": "automatic",
      "client_secret": "pi_3OcAB4CS3h9RvET91GwfYAEe_secret_x3vTtIXKpin4axhR4FOa6f2ln",
      "confirmation_method": "automatic",
      "created": 1706117034,
      "currency": "usd",
      "customer": "cus_PQuBET7THIxBXp",
      "description": null,
      "id": "pi_3OcAB4CS3h9RvET91GwfYAEe",
      "invoice": null,
      "last_payment_error": {
        "charge": "ch_3OcAB4CS3h9RvET91coEWlA3",
        "code": "card_declined",
        "decline_code": "generic_decline",
        "doc_url": "https://stripe.com/docs/error-codes/card-declined",
        "message": "Your card was declined.",
        "payment_method": {
          "billing_details": {
            "address": {
              "city": null,
              "country": null,
              "line1": null,
              "line2": null,
              "postal_code": null,
              "state": null
            },
            "email": null,
            "name": null,
            "phone": null
          },
          "card": {
            "brand": "visa",
            "checks": {
              "address_line1_check": null,
              "address_postal_code_check": null,
              "cvc_check": "pass"
            },
            "country": "US",
            "exp_month": 1,
            "exp_year": 2025,
            "fingerprint": "udqYSEm64uZHaW2t",
            "funding": "credit",
            "generated_from": null,
            "last4": "0002",
            "networks": {
              "available": [
                "visa"
              ],
              "preferred": null
            },
            "three_d_secure_usage": {
              "supported": true
            },
            "wallet": null
          },
          "created": 1706117034,
          "customer": null,
          "id": "pm_1OcAB4CS3h9RvET9CrzGeS19",
          "livemode": false,
          "metadata": {},
          "object": "payment_method",
          "type": "card"
        },
        "type": "card_error"
      },
      "latest_charge": "ch_3OcAB4CS3h9RvET91coEWlA3",
      "livemode": false,
      "metadata": {},
      "next_action": null,
      "object": "payment_intent",
      "on_behalf_of": null,
      "payment_method": null,
      "payment_method_configuration_details": {
        "id": "pmc_1KBcGuCS3h9RvET9csDvh2Kc",
        "parent": null
      },
      "payment_method_options": {
        "card": {
          "installments": null,
          "mandate_options": null,
          "network": null,
          "request_three_d_secure": "automatic"
        },
        "link": {
          "persistent_token": null
        }
      },
      "payment_method_types": [
        "card",
        "link"
      ],
      "processing": null,
      "receipt_email": null,
      "review": null,
      "setup_future_usage": null,
      "shipping": null,
      "source": null,
      "statement_descriptor": null,
      "statement_descriptor_suffix": null,
      "status": "requires_payment_method",
      "transfer_data": null,
      "transfer_group": null
    },
    "payment_method": {
      "billing_details": {
        "address": {
          "city": null,
          "country": null,
          "line1": null,
          "line2": null,
          "postal_code": null,
          "state": null
        },
        "email": null,
        "name": null,
        "phone": null
      },
      "card": {
        "brand": "visa",
        "checks": {
          "address_line1_check": null,
          "address_postal_code_check": null,
          "cvc_check": "pass"
        },
        "country": "US",
        "exp_month": 1,
        "exp_year": 2025,
        "fingerprint": "udqYSEm64uZHaW2t",
        "funding": "credit",
        "generated_from": null,
        "last4": "0002",
        "networks": {
          "available": [
            "visa"
          ],
          "preferred": null
        },
        "three_d_secure_usage": {
          "supported": true
        },
        "wallet": null
      },
      "created": 1706117034,
      "customer": null,
      "id": "pm_1OcAB4CS3h9RvET9CrzGeS19",
      "livemode": false,
      "metadata": {},
      "object": "payment_method",
      "type": "card"
    },
    "request_log_url": "https://dashboard.stripe.com/test/logs/req_HVTSdw5PcpKbeB?t=1706117034",
    "setup_intent": null,
    "source": null,
    "type": "card_error"
};

export const braintreePostDeclineRawJson = {
  id: "kdjxmdor3",
  status: "gateway_rejected",
  type: "sale",
  currency_iso_code: "USD",
  amount: 51.57,
  amount_requested: "51.57",
  merchant_account_id: "Test Seller",
  sub_merchant_account_id: null,
  master_merchant_account_id: null,
  order_id: "19849146",
  created_at: "2023-04-09T03:50:02Z",
  updated_at: "2023-04-09T03:50:02Z",
  customer: {
    id: "12345678910",
    first_name: "Tom",
    last_name: "Brady",
    company: null,
    email: "tom@brades.com",
    website: null,
    phone: "12345678",
    fax: null,
    global_id: "sfsdfsfdgfgfh",
  },
  billing: {
    id: "32",
    first_name: null,
    last_name: null,
    company: null,
    street_address: "12 Cross Street",
    extended_address: null,
    locality: "Test Town",
    region: "CA",
    postal_code: "90210",
    country_name: "United States of America",
    country_code_alpha_2: "US",
    country_code_alpha_3: "USA",
    country_code_numeric: "840",
  },
  refund_id: null,
  refund_ids: [],
  refunded_transaction_id: null,
  partial_settlement_transaction_ids: [],
  authorized_transaction_id: null,
  settlement_batch_id: null,
  shipping: {
    id: null,
    first_name: null,
    last_name: null,
    company: null,
    street_address: null,
    extended_address: null,
    locality: null,
    region: null,
    postal_code: null,
    country_name: null,
    country_code_alpha_2: null,
    country_code_alpha_3: null,
    country_code_numeric: null,
  },
  custom_fields: {},
  account_funding_transaction: false,
  avs_error_response_code: null,
  avs_postal_code_response_code: null,
  avs_street_address_response_code: null,
  cvv_response_code: null,
  gateway_rejection_reason: "fraud",
  processor_authorization_code: null,
  processor_response_code: "",
  processor_response_text: "Unavailable",
  additional_processor_response: null,
  voice_referral_number: null,
  purchase_order_number: null,
  tax_amount: null,
  tax_exempt: false,
  sca_exemption_requested: null,
  processed_with_network_token: false,
  credit_card: {
    token: "a1b2c3d4",
    bin: "123456",
    last_4: "6789",
    card_type: "Visa",
    expiration_month: "02",
    expiration_year: "2024",
    customer_location: "US",
    cardholder_name: null,
    image_url:
      "https://assets.braintreegateway.com/payment_method_logo/visa.png?environment=production",
    is_network_tokenized: false,
    prepaid: "No",
    healthcare: "No",
    debit: "No",
    durbin_regulated: "No",
    commercial: "No",
    payroll: "No",
    issuing_bank: "Test Bank",
    country_of_issuance: "USA",
    product_id: "A",
    global_id: "sdfasdgfsfgfdgdfgsd",
    account_type: "credit",
    unique_number_identifier: "dsfgfdsgfvcvdshy",
    venmo_sdk: false,
    account_balance: null,
  },
  status_history: [
    {
      timestamp: "2023-04-09T03:50:02Z",
      amount: 51.57,
      status: "gateway_rejected",
      user: "Test",
      transaction_source: "api",
    },
  ],
  plan_id: null,
  subscription_id: null,
  subscription: {
    billing_period_end_date: null,
    billing_period_start_date: null,
  },
  add_ons: [],
  discounts: [],
  descriptor: {
    name: null,
    phone: null,
    url: null,
    gateway: "",
  },
  recurring: false,
  channel: null,
  service_fee_amount: null,
  escrow_status: null,
  disbursement_details: {
    disbursement_date: null,
    settlement_amount: null,
    settlement_currency_iso_code: null,
    settlement_currency_exchange_rate: null,
    settlement_base_currency_exchange_rate: null,
    funds_held: null,
    success: null,
  },
  disputes: [],
  ach_return_responses: [],
  authorization_adjustments: [],
  payment_instrument_type: "credit_card",
  processor_settlement_response_code: "",
  processor_settlement_response_text: "",
  network_response_code: null,
  network_response_text: null,
  risk_data: {
    id: "kdjxmdor3",
    decision: "Decline",
    fraud_service_provider: "fraud_protection",
    device_data_captured: false,
    liability_shift: {},
    decision_reasons: ["Transaction Risk Score"],
  },
  three_d_secure_info: null,
  ships_from_postal_code: null,
  shipping_amount: null,
  discount_amount: null,
  network_transaction_id: null,
  processor_response_type: null,
  authorization_expires_at: null,
  retry_ids: [],
  retried_transaction_id: null,
  refund_global_ids: [],
  partial_settlement_transaction_global_ids: [],
  refunded_transaction_global_id: null,
  authorized_transaction_global_id: null,
  global_id: "sdfasdgfsfgfdgdfgsd",
  graphql_id: "sdfasdgfsfgfdgdfgsd",
  retry_global_ids: [],
  retried_transaction_global_id: null,
  retrieval_reference_number: null,
  ach_return_code: null,
  installment_count: null,
  installments: [],
  refunded_installments: [],
  response_emv_data: null,
  acquirer_reference_number: null,
  merchant_identification_number: "1234567899345",
  terminal_identification_number: "00000001",
  merchant_name: "LIVEWELL",
  merchant_address: {
    street_address: null,
    locality: "Cheery",
    region: "CA",
    postal_code: "90210",
    phone: "123-456-7890",
  },
  pin_verified: false,
  debit_network: null,
  processing_mode: null,
  payment_receipt: {
    id: "kdjxmdor3",
    global_id: "sdfasdgfsfgfdgdfgsd",
    amount: "51.57",
    currency_iso_code: "USD",
    processor_response_code: "",
    processor_response_text: "Unavailable",
    processor_authorization_code: null,
    merchant_name: "Test",
    merchant_address: {
      street_address: null,
      locality: "Cheery",
      region: "CA",
      postal_code: "90210",
      phone: "123-456-7890",
    },
    merchant_identification_number: "1234567899345",
    terminal_identification_number: "00000001",
    type: "sale",
    pin_verified: false,
    processing_mode: null,
    network_identification_code: null,
    card_type: "Visa",
    card_last_4: "1234",
    account_balance: null,
  },
  gateway: "",
  billing_details: {
    id: "32",
    first_name: null,
    last_name: null,
    company: null,
    street_address: "12 Cross Street",
    extended_address: null,
    locality: "Test Town",
    region: "CA",
    postal_code: "90210",
    country_name: "United States of America",
    country_code_alpha_2: "US",
    country_code_alpha_3: "USA",
    country_code_numeric: "840",
    gateway: "",
  },
  credit_card_details: {
    token: "a1b2c3d4",
    bin: "123456",
    last_4: "6789",
    card_type: "Visa",
    expiration_month: "02",
    expiration_year: "2024",
    customer_location: "US",
    cardholder_name: null,
    image_url:
      "https://assets.braintreegateway.com/payment_method_logo/visa.png?environment=production",
    is_network_tokenized: false,
    prepaid: "No",
    healthcare: "No",
    debit: "No",
    durbin_regulated: "No",
    commercial: "No",
    payroll: "No",
    issuing_bank: "Test Bank",
    country_of_issuance: "USA",
    product_id: "A",
    global_id: "sdfasdgfsfgfdgdfgsd",
    account_type: "credit",
    unique_number_identifier: "dsfgfdsgfvcvdshy",
    venmo_sdk: false,
    account_balance: null,
    graphql_id: "sdfasdgfsfgfdgdfgsd",
    gateway: "",
    is_expired: null,
    billing_address: null,
  },
  customer_details: {
    id: "12345678910",
    first_name: "Tom",
    last_name: "Brady",
    company: null,
    email: "tom@brades.com",
    website: null,
    phone: "12345678",
    fax: null,
    global_id: "sfsdfsfdgfgfh",
    graphql_id: "sfsdfsfdgfgfh",
    gateway: "",
    payment_methods: [],
  },
  shipping_details: {
    id: null,
    first_name: null,
    last_name: null,
    company: null,
    street_address: null,
    extended_address: null,
    locality: null,
    region: null,
    postal_code: null,
    country_name: null,
    country_code_alpha_2: null,
    country_code_alpha_3: null,
    country_code_numeric: null,
    gateway: "",
  },
  subscription_details: {
    billing_period_end_date: null,
    billing_period_start_date: null,
  },
};
// Cancel transaction retry

export const cancelTransactionSuccessfulResponseStipe = {
  payment_system_transaction_id: "ch_3SdV3qaS83yFrt6T1rlSFoRs",
  status: "cancelled",
};

export const cancelTransactionSuccessfulResponseBraintree = {
  payment_system_transaction_id: "dhncoprhs",
  status: "cancelled",
};

export const cancelTransactionValidationError = {
  detail: "Not Found",
};

export const cancelTransactionDeclineHeaders = [];

export const cancelTransactionSelectOptions = [
  {
    label: "Successful - Cancel transaction retry",
    value: "Successful - Cancel transaction retry",
  },
  {
    label: "Failed - Cancel transaction retry",
    value: "Failed - Cancel transaction retry",
  },
];

// Webhook event

export const webhookEventSuccessfulResponse = {};

export const webhookEventHeaders = [
  {
    key: "Content-Type",
    value: "application/json",
  },
];

export const webhookEventSelectOptions = [
  {
    label: "Successful Response",
    value: "Successful Response",
  },
];
