import React from "react";
import { Descope } from "@descope/react-sdk";
import styles from "./auth.module.scss";

function DescopeSignin() {
  return (
    <div className={styles.descopeContainer}>
      <div className={styles.descopeInnerContainer}>
        <Descope flowId="sign-in" theme="light" />
      </div>
    </div>
  );
}

export default DescopeSignin;
