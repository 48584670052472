import React from "react";
import PropTypes from "prop-types";
import styles from "./TextContent.module.scss";

function PanelHeader({ title }) {
  return <h1 className={styles.panelHeader}>{title}</h1>;
}

PanelHeader.propTypes = {
  title: PropTypes.string,
};

export default PanelHeader;
