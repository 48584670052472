import React, { useContext, useEffect, useRef } from "react";
import NavContext from "../../../../../../context/NavContext";
import SideMenuContext from "../../../../../../context/SideMenuContext";
import { scrollToElement } from "../../../../../../utils/helperFunctions";
import HeadersAndParams from "../../../../../Shared/UrlExample/HeadersAndParams/HeadersAndParams";
import UrlExample from "../../../../../Shared/UrlExample/UrlExample";
import styles from "../../../TextContent.module.scss";

function TransactionGet() {
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { apiOrSandbox } = useContext(NavContext);
  const myRef = useRef();

  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.transactionGet,
      scroll.setTransactionGet,
      toggleScroll
    );
  }, [scroll.transactionGet]);
  return (
    <div ref={myRef} className={styles.transactionTextContainer}>
      <h2 className={styles.subTitle}>
        <span className={`${styles.greenText} ${styles.titleSpace}`}>
          GET {""}
        </span>
        Transaction Get
      </h2>
      <UrlExample
        text={`https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/?search={{TX_ID}}`}
      />
      <p className={styles.mediumText}>Get a specific transaction.</p>
      <HeadersAndParams
        apiKey={true}
        apiKeyValue="TEST_KEY"
        paramsKey1="search"
        paramsValue1="{{TX_ID}}"
        paramsKey2="apiKey"
        paramsValue2="TEST_KEY"
      />
    </div>
  );
}

export default TransactionGet;
