import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import styles from "./NavBarItem.module.scss";
import "./NavBarItem.css";

function NavBarItem({ label, value, setter, options }) {
  const handleChange = (_, v) => {
    setter(v);
    setter(options[v?.key]);
  };

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <Select
        value={value?.value}
        dropdownMatchSelectWidth={false}
        onSelect={handleChange}
        className="navbarSelect">
        {options?.map((option, idx) => (
          <Select.Option key={idx} className={styles.selectOption}>
            {option?.value}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

NavBarItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setter: PropTypes.func,
  options: PropTypes.array,
};

export default NavBarItem;
