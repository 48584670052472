import React from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";
import styles from "./HeadersAndParams.module.scss";
import "./HeadersAndParams.css";

function HeadersAndParams({
  apiKeyValue,
  paramsKey1,
  paramsValue1,
  paramsKey2,
  paramsValue2,
}) {
  return (
    <>
      <div>
        <p className={styles.title}>Headers</p>
        <Divider />

        <div className={styles.content}>
          <div className={styles.textContainer}>
            <p className={styles.key}>Accept</p>
            <p className={styles.value}>application/json</p>
          </div>
          {apiKeyValue && (
            <div className={styles.textContainer}>
              <p className={styles.key}>apiKey</p>
              <p className={styles.value}>{apiKeyValue}</p>
            </div>
          )}
        </div>
      </div>

      {paramsKey1 && (
        <div>
          <p className={styles.title}>Params</p>
          <Divider />

          <div className={styles.content}>
            <div className={styles.textContainer}>
              <p className={styles.key}>{paramsKey1}</p>
              <p className={styles.value}>{paramsValue1}</p>
            </div>
            {paramsKey2 && (
              <div className={styles.textContainer}>
                <p className={styles.key}>{paramsKey2}</p>
                <p className={styles.value}>{paramsValue2}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

HeadersAndParams.propTypes = {
  apiKeyValue: PropTypes.string,
  paramsKey1: PropTypes.string,
  paramsValue1: PropTypes.string,
  paramsKey2: PropTypes.string,
  paramsValue2: PropTypes.string,
};

export default HeadersAndParams;
