import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";
/* eslint-disable */

function Curl() {
  const { apiOrSandbox } = useContext(NavContext);

  return (
    <p>
      {`curl --location -g --request POST 'https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/webhook'`}{" "}
    </p>
  );
}

export default Curl;
