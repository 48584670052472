import React, { useContext } from "react";
import JSONPretty from "react-json-pretty";
import NavContext from "../../../../../context/NavContext";

function PythonRequest() {
  const { paymentSystem } = useContext(NavContext);
  const apiCall = paymentSystem?.key === "0" ? "PUT" : "POST";
  const headers = {
    Accept: "application/json",
    apiKey: "TEST_KEY",
  };

  return (
    <div>
      <p>import requests</p>
      <p>
        {`url = "https://{{merchant_name}}.bouncepay.io/api/v1/transaction/{{transaction_id}}/cancel"`}
      </p>

      <div>
        <span>{`payload = {}`}</span>

        <br />
        <span>{`headers = `}</span>

        <JSONPretty
          style={{ backgroundColor: "none !important" }}
          id="json-pretty"
          data={headers}
        />
      </div>

      <p>
        {`response = requests.request("${apiCall}", url, headers=headers, data=payload)`}
      </p>
      <p> {`print(response.text)`}</p>
    </div>
  );
}

export default PythonRequest;
