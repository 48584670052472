import React, { useContext } from "react";
import { enviornmentItems, languageItems } from "./variables";
import NavContext from "../../context/NavContext";
import NavBarItem from "./NavBarItem/NavBarItem";
import styles from "./NavBar.module.scss";

function NavBar() {
  const { environment, setEnvironment, language, setLanguage } =
    useContext(NavContext);

  return (
    <div className={styles.navbarContainer}>
      <NavBarItem
        label="ENVIRONMENT"
        value={environment}
        setter={setEnvironment}
        options={enviornmentItems}
      />
      <NavBarItem
        label="LANGUAGE"
        value={language}
        setter={setLanguage}
        options={languageItems}
      />
    </div>
  );
}

export default NavBar;
