import React from "react";
import PropTypes from "prop-types";
import styles from "./UrlExample.module.scss";

function UrlExample({ text }) {
  return <div className={styles.container}>{text}</div>;
}

UrlExample.propTypes = {
  text: PropTypes.string,
};

export default UrlExample;
