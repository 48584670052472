import React, { useContext, useEffect, useState } from "react";
import BackendIntegration from "./BackendIntegration";
import FrontendIntegration from "./FrontendIntegration";
import Introduction from "./Introduction";
import styles from "./TextContent.module.scss";
import SupportedFlows from "./SupportedFlows";
import { Collapse } from "antd";
import SideMenuContext from "../../../context/SideMenuContext";
import PanelHeader from "./PanelHeader";
import "./TextContent.css";
import WelcomeSection from "./WelcomeSection";

function TextContent() {
  const { openSection, setOpenSection } = useContext(SideMenuContext);
  const [activeKeys, setActiveKeys] = useState("1");
  const { Panel } = Collapse;

  useEffect(() => {
    setActiveKeys(openSection);
  }, [openSection]);

  const handlePanelChange = (keys) => {
    setActiveKeys(keys);
    setOpenSection(keys);
  };

  return (
    <div className={styles.container}>
      <WelcomeSection />
      <Collapse
        defaultActiveKey={["1"]}
        activeKey={activeKeys}
        onChange={handlePanelChange}
        size="large"
        accordion
        ghost>
        <Panel header={<PanelHeader title="Welcome to Bounce documentation" />} key="1">
          <Introduction />
        </Panel>
        <Panel header={<PanelHeader title="Supported Flows" subtitle="test"/>} key="2">
          <SupportedFlows />
        </Panel>
        <Panel header={<PanelHeader title="Backend Integration" />} key="3">
          <BackendIntegration />
        </Panel>
        <Panel header={<PanelHeader title="Frontend Integration" />} key="4">
          <FrontendIntegration />
        </Panel>
      </Collapse>
    </div>
  );
}

export default TextContent;
