import React, { useContext, useEffect, useRef } from "react";
import { Divider } from "antd";
import styles from "../../../TextContent.module.scss";
import TransactionList from "./TransactionList";
import { scrollToElement } from "../../../../../../utils/helperFunctions";
import SideMenuContext from "../../../../../../context/SideMenuContext";
import TransactionGet from "./TransactionGet";
import TransactionPostDecline from "./TransactionPostDecline";
import CancelTransactionRetry from "./CancelTransactionRetry";
import ExampleCode from "../../../../CodeDisplay/ExampleCode";
import {
  cancelTransactionDeclineHeaders,
  cancelTransactionSelectOptions,
  cancelTransactionSuccessfulResponseBraintree,
  cancelTransactionSuccessfulResponseStipe,
  cancelTransactionValidationError,
  exampleCodeSelectOptions,
  transactionGetHeaders,
  transactionGetSuccessfulResponseBraintree,
  transactionGetSuccessfulResponseStipe,
  transactionGetValidationError,
  transactionListHeaders,
  transactionListSuccessfulResponseBraintree,
  transactionListSuccessfulResponseStripe,
  transactionListValidationError,
  transactionPostDeclineHeaders,
  transactionPostDeclineSelectOptions,
  braintreeTransactionPostDeclineSuccessfulResponse,
  stripeTransactionPostDeclineSuccessfulResponse
} from "../../../../../../utils/codeExampleVariables";
import TransactionListRequests from "../../../../CodeDisplay/ExampleRequests/TransactionListRequests/TransactionListRequests";
import TransactionGetRequests from "../../../../CodeDisplay/ExampleRequests/TransactionGetRequests/TransactionGetRequests";
import TransactionPostDeclineRequests from "../../../../CodeDisplay/ExampleRequests/TransactionPostDeclineRequests/TransactionPostDeclineRequests";
import CancelTransactionRetryRequests from "../../../../CodeDisplay/ExampleRequests/CancelTransactionRetryRequests/CancelTransactionRetryRequests";
import NavContext from "../../../../../../context/NavContext";
import { useParams } from "react-router-dom";

function Transaction() {
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { isStripe } = useContext(NavContext);
  const { paymentSystem, endpoint } = useParams();
  const myRef = useRef();

  useEffect(() => {
    switch (endpoint) {
      case "transactionPostDecline":
        scroll.setTransactionPostDecline(true);
        break;
      case "webhookEvent":
        scroll.setWebhookEvent(true);
        break;
    }
  }, [paymentSystem, endpoint]);

  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.transaction,
      scroll.setTransaction,
      toggleScroll
    );
  }, [scroll.transaction]);
  return (
    <div className="innerContentContainer" ref={myRef}>
      <h2 className={styles.subTitle}>API Endpoints</h2>
      <p className={`${styles.paragraph} ${styles.text}`}>
        This set of APIs is used to query transactions that were sent to Bounce
        and the posting of a declined transaction to be processed by Bounce.
      </p>
      <Divider />
      <div className={styles.transactionTextAndCode}>
        <TransactionPostDecline />
        <ExampleCode
          id="transaction-decline"
          successfulResponse={
            isStripe
              ? stripeTransactionPostDeclineSuccessfulResponse
              : braintreeTransactionPostDeclineSuccessfulResponse
          }
          headers={transactionPostDeclineHeaders}
          dropdownOptions={transactionPostDeclineSelectOptions}
          exampleRequest={<TransactionPostDeclineRequests />}
        />
      </div>

      <div className={styles.transactionTextAndCode}>
        <CancelTransactionRetry />
        <ExampleCode
          id="transaction-cancel-transaction-retry"
          successfulResponse={
            isStripe
              ? cancelTransactionSuccessfulResponseStipe
              : cancelTransactionSuccessfulResponseBraintree
          }
          validationError={cancelTransactionValidationError}
          headers={cancelTransactionDeclineHeaders}
          dropdownOptions={cancelTransactionSelectOptions}
          exampleRequest={<CancelTransactionRetryRequests />}
        />
      </div>

      <div className={styles.transactionTextAndCode}>
        <TransactionList />
        <ExampleCode
          id="transaction-list"
          successfulResponse={
            isStripe
              ? transactionListSuccessfulResponseStripe
              : transactionListSuccessfulResponseBraintree
          }
          validationError={transactionListValidationError}
          headers={transactionListHeaders}
          dropdownOptions={exampleCodeSelectOptions}
          exampleRequest={<TransactionListRequests />}
        />
      </div>

      <div className={styles.transactionTextAndCode}>
        <TransactionGet />
        <ExampleCode
          id="transaction-get"
          successfulResponse={
            isStripe
              ? transactionGetSuccessfulResponseStipe
              : transactionGetSuccessfulResponseBraintree
          }
          validationError={transactionGetValidationError}
          headers={transactionGetHeaders}
          dropdownOptions={exampleCodeSelectOptions}
          exampleRequest={<TransactionGetRequests />}
        />
      </div>
    </div>
  );
}

export default Transaction;
