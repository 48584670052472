import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";
/* eslint-disable */

function Ruby() {
  const { apiOrSandbox } = useContext(NavContext);
  return (
    <div>
      <p>
        <span>{`require "uri"`}</span>
        <br />
        <span>{`require "net/http"`}</span>
      </p>
      <p>
        <span>{`url = URI("https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/webhook/")`}</span>
        <br />
      </p>

      <p>
        <span>{`https = Net::HTTP.new(url.host, url.port)`}</span>
        <br />
        <span>{`https.use_ssl = true`}</span>
      </p>

      <p>
        <span>{`request = Net::HTTP::Post.new(url)`}</span>
        <br />
      </p>

      <p>
        <span>{`response = https.request(request)`}</span>
        <br />
        <span>{`puts response.read_body`}</span>
      </p>
    </div>
  );
}

export default Ruby;
