import React, { useContext, useEffect } from "react";
import SideMenuContext from "../../../context/SideMenuContext";
import styles from "../TextContent/TextContent.module.scss";
import paymentSystemsStyles from "./PaymentSystemsText.module.scss";
import { Space } from "antd";

function Paypal() {
  const { setOpenSection } = useContext(SideMenuContext);

  useEffect(() => {
    setOpenSection("8");
  }, []);

  return (
    <div className={paymentSystemsStyles.textInnerContentContainer}>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <h1 className={paymentSystemsStyles.title}>
          PayPal subscription retries
        </h1>
        <div className={paymentSystemsStyles.section}>
          <h2>How is Bounce integrated with PayPal</h2>
          <p>
            Since a checkout with PayPal requires the involvement of the buyer to complete, 
            Bounce can only come into play when a recurring payment fails.
          </p>
          <p>
            The normal flow for a recurring payment is as follows:
          </p>
          <ol className={paymentSystemsStyles.orderedList}>
            <Space direction="vertical" size={15}>
              <li>Merchant creates a subscription for the user and the user confirms it with PayPal</li>
              <li>
                On the payment due date PayPal attempts to charge the buyer. If the
                payment fails, PayPal makes two additional attempt every 5 days.
              </li>
              <li>
                Once PayPal’s retries are finished, the unpaid amount is added
                to the buyer’s outstanding balance (if it was configured to do so)
                and a webhook for failed subscription payment is sent and the 
                subscription is disabled.
              </li>
            </Space>
          </ol>
        </div>
        <div className={paymentSystemsStyles.section}>
          <p>
            With Bounce the end of the flow will change. 
          </p>
          <ol className={paymentSystemsStyles.orderedList}>
            <Space direction="vertical" size={15}>
              <li>Merchant creates a subscription for the user and the user confirms it with PayPal</li>
              <li>
                On the payment due date PayPal attempts to charge the buyer. If the
                payment fails, PayPal makes two additional attempt every 5 days.
              </li>
              <li>
                When such payment fails, Bounce will receive a failed payment webhook from PayPal.
              </li>
              <li>
                Bounce will analyse the transaction, if the analysis shows that
                there is a good chance of recovery, Bounce creates a retry
                schedule and attempts to capture the outstanding amount.
              </li>
            </Space>
          </ol>
        </div>

        <img
          src="/images/paypal-flow-1.png"
          alt=""
          className={paymentSystemsStyles.image}
        />

        <div className={paymentSystemsStyles.section}>
          <h2>Optional Bounce Webhooks</h2>
          <h3>Successful retry notification</h3>
          <p>
            Bounce can send a webhook notification to inform the merchant that
            it successfully charged a failed subscription payment.
          </p>
        </div>
        <div className={paymentSystemsStyles.section}>
          <h3>Transaction abandoned notification</h3>
          <p>
            Bounce can send a webhook notification to inform the merchant that a
            failed subscription payment will not be retried anymore. There are
            two scenarios where this webhook can be sent:
          </p>

          <ol className={paymentSystemsStyles.orderedList}>
            <Space direction="vertical" size={15}>
              <li>
                When a subscription payment failed and Bounce’s model shows
                small chance of recovery, or that there is a high chance of it
                being charged back
              </li>
              <li>
                When Bounce made all it’s planned attempts and is not going to
                retry again
              </li>
            </Space>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Paypal;
