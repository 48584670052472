import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavProvider } from "./context/NavContext";
import { SideMenuProvider } from "./context/SideMenuContext";
import Spinner from "./components/Shared/spinner/Spinner";
import DescopeSignin from "./components/auth/DescopeSignin";
import AuthContext from "./context/AuthContext";
import HomeComponent from "./routes/HomeComponent";
import ApiEndpoints from "./routes/ApiEndpoints";
import { routes } from "./routes/routes";
import "./styles/main.scss";
import "./styles/antd.less";
import PaypalComponent from "./routes/PaypalComponent";

function App() {
  const { isAuthenticated, isSessionLoading, isUserLoading } =
    useContext(AuthContext);

  return (
    <>
      {(isSessionLoading || isUserLoading) && <Spinner />}
      {isAuthenticated && (
        <Router>
          <NavProvider>
            <SideMenuProvider>
              <Routes>
                <Route exact path="/" element={<HomeComponent />} />
                <Route
                  exact
                  path={routes.apiEndpoints.mainPattern}
                  element={<ApiEndpoints />}
                />
                <Route
                  exact
                  path={routes.apiEndpoints.endpointPattern}
                  element={<ApiEndpoints />}
                />
                <Route
                  exact
                  path={routes.paypal.main}
                  element={<PaypalComponent />}
                />
              </Routes>
            </SideMenuProvider>
          </NavProvider>
        </Router>
      )}

      {!isAuthenticated && <DescopeSignin />}
    </>
  );
}

export default App;
