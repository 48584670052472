import React from "react";
/* eslint-disable */

function Curl() {
  return (
    <p>
      <span>
        {`curl --location -g --request POST 'https://{{merchant_name}}.bouncepay.io/api/v1/transaction/{{transaction_id}}/cancel'`}{" "}
        \
      </span>
      <br />
      <span>{`--header 'Accept: application/json'`} \</span>
      <br />
      <span>{`--header 'apiKey: TEST_KEY'`} </span>
      <br />
    </p>
  );
}

export default Curl;
