import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";

function JavaUnirset() {
  const { apiOrSandbox } = useContext(NavContext);
  return (
    <p>
      <span>{`Unirest.setTimeouts(0, 0);`}</span>
      <br />
      <span>{`HttpResponse<String> response = Unirest.get("https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/?skip=0&limit=10").asString();`}</span>
    </p>
  );
}

export default JavaUnirset;
