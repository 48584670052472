import React from "react";
import JSONPretty from "react-json-pretty";

function NodeJsNative() {
  const options = {
    method: "POST",
    hostname: "{{merchant_name}}.bouncepay.io",
    path: "/api/v1/transaction/{{transaction_id}}/cancel",
    headers: {
      Accept: "application/json",
      apiKey: "TEST_KEY",
    },
    maxRedirects: 20,
  };

  return (
    <div>
      <p>
        <span>{`var https = require('follow-redirects').https;`}</span>
        <br />
        <span>{`var fs = require('fs');`}</span>
      </p>

      <p>
        var options ={" "}
        <JSONPretty
          style={{ backgroundColor: "none !important" }}
          id="json-pretty"
          data={options}
        />
      </p>

      <p>
        <span>{`var req = https.request(options, function (res) {`}</span>
        <br />
        <p style={{ paddingLeft: "0.5rem" }}>
          <span>{`var chunks = [];`}</span>
          <p>
            <span>{`res.on("data", function (chunk) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`chunks.push(chunk);`}</span>
            <br />
            <span>{`});`}</span>
          </p>

          <p>
            <span>{`res.on("end", function (chunk) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`var body = Buffer.concat(chunks);`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`console.log(body.toString());`}</span>
            <br />
            <span>{`});`}</span>
          </p>

          <p>
            <span>{`res.on("error", function (error) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`console.error(error);`}</span>
            <br />
            <span>{`});`}</span>
          </p>
        </p>
        <span>{`});`}</span>
      </p>

      <p>{`req.end();`}</p>
    </div>
  );
}

export default NodeJsNative;
