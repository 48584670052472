import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider as DescopeAuthProvider } from "@descope/react-sdk";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <DescopeAuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </DescopeAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
