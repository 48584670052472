import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useState } from "react";

const SideMenuContext = createContext(null);

export function SideMenuProvider({ children }) {
  const currentPath = window.location.pathname;
  const [openSection, setOpenSection] = useState("1");
  const [collapsed, setCollapsed] = useState(false);
  const [introductionScroll, setIntroductionScroll] = useState(false);
  const [supportedFlowsScroll, setSupportedFlowsScroll] = useState(false);
  const [backendIntegration, setBackendIntegration] = useState(false);
  const [frontendIntegration, setFrontendIntegration] = useState(false);
  const [apiV1, setApiV1] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [transactionList, setTransactionList] = useState(false);
  const [transactionGet, setTransactionGet] = useState(false);
  const [transactionPostDecline, setTransactionPostDecline] = useState(false);
  const [cancelTransactionRetry, setCancelTransactionRetry] = useState(false);
  const [webhookEvent, setWebhookEvent] = useState(false);

  const toggleScroll = (value, setter) => {
    setter(!value);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (introductionScroll) {
      setOpenSection("1");
    } else if (supportedFlowsScroll) {
      setOpenSection("2");
    } else if (backendIntegration) {
      setOpenSection("3");
    } else if (frontendIntegration) {
      setOpenSection("4");
    }
  }, [
    introductionScroll,
    supportedFlowsScroll,
    backendIntegration,
    frontendIntegration,
  ]);

  const scroll = {
    introductionScroll,
    setIntroductionScroll,
    supportedFlowsScroll,
    setSupportedFlowsScroll,
    backendIntegration,
    setBackendIntegration,
    frontendIntegration,
    setFrontendIntegration,
    apiV1,
    setApiV1,
    transaction,
    setTransaction,
    transactionList,
    setTransactionList,
    transactionGet,
    setTransactionGet,
    transactionPostDecline,
    setTransactionPostDecline,
    cancelTransactionRetry,
    setCancelTransactionRetry,
    webhookEvent,
    setWebhookEvent,
  };
  const value = {
    collapsed,
    toggleCollapsed,
    toggleScroll,
    scroll,
    openSection,
    setOpenSection,
    currentPath,
  };

  return (
    <SideMenuContext.Provider value={value}>
      {children}
    </SideMenuContext.Provider>
  );
}

SideMenuProvider.propTypes = {
  children: PropTypes.node,
};
export default SideMenuContext;
