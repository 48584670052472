import React from "react";
import styles from "./TextContent.module.scss";

function SupportedFlows() {
  return (
    <div className={styles.textInnerContentContainer}>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <p>
          The following sections describe the payment flows that are currently
          supported by the Bounce platfrom.
        </p>
        <h3 className={styles.subTitleH3}>Checkout flow</h3>
        <p>
          This flow applies to cases where the buyer initiates a payment on the
          merchants website
        </p>

        <img
          src="/images/supported-flows-1.png"
          alt=""
          className={styles.image}
        />
      </div>

      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <h3 className={styles.subTitleH3}>
          Recurring payment for physical products flow
        </h3>
        <p className={styles.span}>
          This flow applies to cases where the merchant initiates a recurring
          payment on behalf of the buyer. In this flow, the merchant needs to
          know, in real time, whether Bounce approves or declines the payment,
          so that they will know whether to ship the product or not.
        </p>

        <img
          src="/images/supported-flows-2.png"
          alt=""
          className={styles.image}
        />
      </div>

      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <h3 className={styles.subTitleH3}>Subscription creation flow</h3>
        <p className={styles.span}>
          This flow applies to cases where the merchant creates a new
          subscription for a user, and a Bounce decision is required in real
          time , as the user is waiting to finish the checkout.
        </p>
      </div>

      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <h3 className={styles.subTitleH3}>Subscription renewal flow</h3>
        <p className={styles.span}>
          This flow applies to cases where the merchant{"'"}s payment system is
          configured to automatically charge the buyer for the their
          subscription and so the charges are initiated by the payment system
          without the involvement of the merchant.
        </p>
        <p>This flow can work work in several modes:</p>

        <ul className={`${styles.mediumText} ${styles.li}`}>
          <li>
            Bounce takes over retrying the failed payment and retries the
            renewal - In this mode, Bounce can inform the merchant through a
            callback, whether it intends to retry or not.
          </li>
          <li>
            Bounce takes over retrying the failed payment, after the payment
            system, or other 3rd party, stopped retrying - In this mode, Bounce
            can inform the merchant through a callback, whether it intends to
            retry or not.
          </li>
          <li>
            A/B testing - In this mode, Bounce randomly chooses which failed
            payments will be sent for assessment and, and when approved, takes
            over the retries.
          </li>
        </ul>

        <img
          src="/images/supported-flows-3.png"
          alt=""
          className={styles.image}
        />
      </div>

      {/* Supported metadata */}
      <h2 className={styles.subTitle}>Supported metadata</h2>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <p className={styles.span}>
          Most payment systems allow merchants to add metadata to a transaction.
          This metadata can be used by Bounce do better categorise transactions
          in order to make better assessments and for reporting purposes.
        </p>
        <p className={styles.mediumText}>
          Bounce currently supports updating the metadata on these events:
        </p>
        <ul className={styles.li}>
          <li>Creating new transactions (i.e. bounce retries)</li>
          <li>When approving or declining a transaction</li>
        </ul>
      </div>
    </div>
  );
}

export default SupportedFlows;
