import { Button } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import NavContext from "../../../../../../context/NavContext";
import AuthContext from "../../../../../../context/AuthContext";
import SideMenuContext from "../../../../../../context/SideMenuContext";
import { scrollToElement } from "../../../../../../utils/helperFunctions";
import HeadersAndParams from "../../../../../Shared/UrlExample/HeadersAndParams/HeadersAndParams";
import UrlExample from "../../../../../Shared/UrlExample/UrlExample";
import PlainText from "../../../../CodeDisplay/PlainText";
import styles from "../../../TextContent.module.scss";
import { payloadOptions } from "./variables";

function TransactionPostDecline() {
  const { isAdminOrStripe } = useContext(AuthContext);
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { apiOrSandbox } = useContext(NavContext);
  const [selectedPayload, setSelectedPayload] = useState(
    payloadOptions[0]?.key
  );
  const myRef = useRef();

  const handlePayloadSelection = (option) => {
    setSelectedPayload(option);
  };

  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.transactionPostDecline,
      scroll.setTransactionPostDecline,
      toggleScroll
    );
  }, [scroll.transactionPostDecline]);
  return (
    <div ref={myRef} className={styles.transactionTextContainer}>
      <h2 className={styles.subTitle}>
        <span className={`${styles.orangeText} ${styles.titleSpace}`}>
          POST {""}
        </span>
        Transaction Post Decline
      </h2>
      <UrlExample
        text={`https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/`}
      />

      <p className={styles.text}>
        This endpoint is used to send declined transactions to Bounce, for
        Bounce to process and reply with approve/decline decision.
      </p>
      <p className={styles.text}>
        The object in the request should be your payment system transaction
        object.
      </p>

      <HeadersAndParams apiKey={true} apiKeyValue="TEST_KEY" />

      <div className={styles.bodyContainer}>
        <p className={styles.sectionTitle}>
          Body <span className={styles.grayedOutText}>raw (json)</span>
        </p>
        {isAdminOrStripe && (
          <div className={styles.payloadButtons}>
            {payloadOptions?.map((option) => (
              <Button
                key={option?.key}
                className={`${styles.payloadButton} ${
                  selectedPayload === option?.key && styles.selectedPayload
                }`}
                onClick={() => handlePayloadSelection(option?.key)}
              >
                {option?.text}
              </Button>
            ))}
          </div>
        )}
      </div>
      <PlainText markup={true} titleText={"json"} />
    </div>
  );
}

export default TransactionPostDecline;
