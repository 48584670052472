import React from "react";
import styles from "./TextContent.module.scss";

function WelcomeSection() {
  return (
    <div className={styles.welcome}>
      <h1>Welcome to Bounce documentation</h1>
      <p>
        Welcome to Bounce Documentation! Here, you&apos;ll discover
        comprehensive guides, detailed flow charts, and essential API endpoints
        to facilitate smooth integration of our services with your backend and
        frontend systems.
      </p>
    </div>
  );
}

export default WelcomeSection;
