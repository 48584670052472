export const routes = {
  apiEndpoints: {
    main: (paymentSystem) => `/api-endpoints/overview/${paymentSystem}`,
    mainPattern: "/api-endpoints/overview/:paymentSystem",
    endpoint: (paymentSystem, endpoint) =>
      `/api-endpoints/${paymentSystem}/${endpoint}`,
    endpointPattern: "/api-endpoints/:paymentSystem/:endpoint",
  },
  paypal: {
    main: "/paypal",
  },
};
