import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import Logout from "../auth/Logout";
import styles from "./User.module.scss";

function User() {
  const { user } = useContext(AuthContext);

  return (
    <div className={styles.container}>
      <p className={styles.user}>{user?.name || user?.email}</p>
      <Logout />
    </div>
  );
}

export default User;
