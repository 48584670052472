import React from "react";
import JSONPretty from "react-json-pretty";
import styles from "./CodeDisplay.module.scss";

var JSONPrettyMon = require('./JsonTheme.css');

function InformationCollected() {
  let successfulResponse = {
    event_name: "connect",
    connector_id: "<payment_system_object_id>",
    connector_method: "<customer_id>"
  }
  return (
    <p id="informationCollected" className={styles.bodyText}>
      <JSONPretty
              theme={JSONPrettyMon}
              id="json-pretty"
              data={successfulResponse}
      /> 
    
    </p>
  );
}

export default InformationCollected;
