import React, { useContext, useEffect, useRef } from "react";
import NavContext from "../../../../../../context/NavContext";
import SideMenuContext from "../../../../../../context/SideMenuContext";
import { scrollToElement } from "../../../../../../utils/helperFunctions";
import HeadersAndParams from "../../../../../Shared/UrlExample/HeadersAndParams/HeadersAndParams";
import UrlExample from "../../../../../Shared/UrlExample/UrlExample";
import styles from "../../../TextContent.module.scss";

function TransactionList() {
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { apiOrSandbox } = useContext(NavContext);
  const myRef = useRef();

  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.transactionList,
      scroll.setTransactionList,
      toggleScroll
    );
  }, [scroll.transactionList]);
  return (
    <div ref={myRef} className={styles.transactionTextContainer}>
      <h2 className={styles.subTitle}>
        <span className={`${styles.greenText} ${styles.titleSpace}`}>
          GET {""}
        </span>
        Transaction List
      </h2>
      <UrlExample
        text={`https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/?skip=0&limit=1`}
      />
      <p className={styles.mediumText}>Get a filtered list of transactions.</p>
      <HeadersAndParams
        apiKey={true}
        apiKeyValue="TEST_KEY"
        paramsKey1="skip"
        paramsValue1="0"
        paramsKey2="limit"
        paramsValue2="1"
      />
    </div>
  );
}

export default TransactionList;
