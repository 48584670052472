import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";
import JSONPretty from "react-json-pretty";
import {
  braintreePostDeclineRawJson,
  stripePostDeclineRawJson,
} from "../../../../../utils/codeExampleVariables";
/* eslint-disable */

function Curl() {
  const { apiOrSandbox, paymentSystem } = useContext(NavContext);

  const data =
    paymentSystem?.key === "0"
      ? stripePostDeclineRawJson
      : braintreePostDeclineRawJson;

  return (
    <p>
      <span>
        {`curl --location -g 'https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/85750734/`}{" "}
        \
      </span>
      <br />
      <span>{`--header 'Accept: application/json'`} \</span>
      <br />
      <span>{`--header 'apiKey: TEST_KEY'`} \</span>
      <br />
      <span>{`--data '`} </span>
      <JSONPretty
        style={{ backgroundColor: "none !important" }}
        id="json-pretty"
        data={data}
      />{" "}
      '
    </p>
  );
}

export default Curl;
