import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";

function PythonRequest() {
  const { apiOrSandbox } = useContext(NavContext);
  return (
    <div>
      <p>import requests</p>
      <p>
        {`url = "https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/85750734/"`}
      </p>
      <p>{`payload={}`}</p>
      <p>{`headers = {}`}</p>

      <p>
        {`response = requests.request("GET", url, headers=headers, data=payload)`}
      </p>
      <p> {`print(response.text)`}</p>
    </div>
  );
}

export default PythonRequest;
