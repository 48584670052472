import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RxCopy } from "react-icons/rx";
import { copyText } from "../../../utils/helperFunctions";
import ExampleResponseBody from "./ExampleResponseBody";
import CustomCodeDropdown from "./CustomDropdown/CustomDropdown";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from "./CodeDisplay.module.scss";
import "./CodeDisplay.css";
import NavContext from "../../../context/NavContext";
import NavBar from "../../NavBar/NavBar";

function ExampleCode({
  id,
  successfulResponse,
  validationError,
  headers,
  dropdownOptions,
  exampleRequest,
}) {
  const { language } = useContext(NavContext);
  const [showResponse, setShowResponse] = useState(true);
  const [showBody, setShowBody] = useState(true);
  const [optionValue, setOptionValue] = useState(dropdownOptions[0]?.value);
  const [showDropdown, setShowDropdown] = useState(false);
  let requestTextToCopy = document.getElementById(id)?.innerText;

  useEffect(() => {
    requestTextToCopy = document.getElementById(id)?.innerText;
  }, [language]);

  const handleCopy = () => {
    let textToCopy = "";
    if (showResponse) {
      textToCopy = successfulResponse;
    } else {
      textToCopy = validationError;
    }
    copyText(JSON.stringify(textToCopy, undefined, 2));
  };

  const toggleShowResponse = () => {
    setShowResponse(!showResponse);
  };

  const toggleShowBody = () => {
    setShowBody(!showBody);
  };

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownValueChange = (value) => {
    toggleShowDropdown();
    if (optionValue !== value) {
      setOptionValue(value);
      toggleShowResponse();
    }
  };

  return (
    <div className={styles.codeWithBarContainer}>
      <NavBar />
      <div className={styles.exmpleCodeContainer}>
        <div
          id="exmpleCodeInnerContainer"
          className={styles.exmpleCodeInnerContainer}>
          <div className={styles.requestTitle}>
            <p>Example Request</p>
            <div
              onClick={toggleShowDropdown}
              className={styles.dropdownContainer}>
              <p className={styles.value}>{optionValue && optionValue}</p>
              <div className={styles.icon}>
                {!showDropdown ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
            </div>
          </div>

          <div className={styles.exmpleCodeBody}>
            <div className={styles.header}>
              <p className={styles.plainText}>{language?.displayText}</p>
              <RxCopy
                className={styles.copyIcon}
                onClick={() => copyText(requestTextToCopy)}
              />
            </div>

            <div className={styles.exampleCodeText} id={id}>
              {exampleRequest}
            </div>
            <CustomCodeDropdown
              handleChange={handleDropdownValueChange}
              options={dropdownOptions}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
            />
          </div>
        </div>

        <div
          id="exmpleCodeInnerContainer"
          className={styles.exmpleCodeInnerContainer}>
          <div className={styles.requestTitle}>
            <p>Example Response</p>
          </div>

          <div className={styles.bodyAndHeadersSelection}>
            <p
              className={`${styles.bodyOrHeadersItem} ${
                showBody && styles.bodyOrHeadersItemSelected
              }`}
              onClick={toggleShowBody}>
              Body
            </p>
            <p
              className={`${styles.bodyOrHeadersItem} ${
                !showBody && styles.bodyOrHeadersItemSelected
              }`}
              onClick={toggleShowBody}>
              Headers
            </p>
          </div>

          <div className={styles.exmpleCodeBody}>
            <div className={styles.header}>
              {showBody && (
                <>
                  <p className={styles.plainText}>json</p>
                  <RxCopy
                    className={styles.copyIcon}
                    onClick={() => {
                      handleCopy();
                    }}
                  />
                </>
              )}
            </div>

            <div className={styles.exampleCodeText} id="exampleResponseCode">
              <ExampleResponseBody
                showResponse={showResponse}
                showBody={showBody}
                successfulResponse={successfulResponse}
                validationError={validationError}
                headers={headers}
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

ExampleCode.propTypes = {
  id: PropTypes.string,
  successfulResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headers: PropTypes.array,
  dropdownOptions: PropTypes.array,
  validationError: PropTypes.object,
  exampleRequest: PropTypes.node,
};

export default ExampleCode;
