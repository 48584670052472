import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./CustomDropdown.module.scss";
import useClickedOutside from "../../../../hooks/useClickedOutside";

function CustomCodeDropdown({
  handleChange,
  options,
  showDropdown,
  setShowDropdown,
}) {
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, setShowDropdown);
  return (
    <div className={styles.container} ref={wrapperRef}>
      {showDropdown && (
        <div id="dropdown" className={styles.dropdown}>
          {options?.map((option, idx) => (
            <p
              key={idx}
              onClick={() => handleChange(option?.value)}
              className={styles.option}
            >
              {option?.value}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

CustomCodeDropdown.propTypes = {
  showDropdown: PropTypes.bool,
  handleChange: PropTypes.func,
  setShowDropdown: PropTypes.func,
  options: PropTypes.array,
};

export default CustomCodeDropdown;
