import React, { useContext } from "react";
import JSONPretty from "react-json-pretty";
import NavContext from "../../../../../context/NavContext";

function NodeJsNative() {
  const { apiOrSandbox } = useContext(NavContext);
  const options = {
    method: "GET",
    hostname: `"{{merchant_name}}.${apiOrSandbox}.bouncepay.io"`,
    path: "/api/v1/transaction/85750734/",
    headers: {},
    maxRedirects: 20,
  };

  return (
    <div>
      <p>
        <span>{`var https = require('follow-redirects').https;`}</span>
        <br />
        <span>{`var fs = require('fs');`}</span>
      </p>

      <p>
        var options ={" "}
        <JSONPretty
          style={{ backgroundColor: "none !important" }}
          id="json-pretty"
          data={options}
        />
      </p>

      <p>
        <span>{`var req = https.request(options, function (res) {`}</span>
        <br />
        <p style={{ paddingLeft: "0.5rem" }}>
          <span>{`var chunks = [];`}</span>
          <p>
            <span>{`res.on("data", function (chunk) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`chunks.push(chunk);`}</span>
            <br />
            <span>{`});`}</span>
          </p>

          <p>
            <span>{`res.on("end", function (chunk) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`var body = Buffer.concat(chunks);`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`console.log(body.toString());`}</span>
            <br />
            <span>{`});`}</span>
          </p>

          <p>
            <span>{`res.on("error", function (error) {`}</span>
            <br />
            <span
              style={{ paddingLeft: "0.5rem" }}
            >{`console.error(error);`}</span>
            <br />
            <span>{`});`}</span>
          </p>
        </p>
        <span>{`});`}</span>
      </p>
      <p>{`req.end();`}</p>
    </div>
  );
}

export default NodeJsNative;
