import React from "react";

function Php() {
  return (
    <div>
      <span>{`<?php`}</span>
      <br />
      <span>{`require_once 'HTTP/Request2.php';`}</span>
      <br />
      <span>{`$request = new HTTP_Request2();`}</span>
      <br />
      <span>{`$request->setUrl('https://{{merchant_name}}.bouncepay.io/api/v1/transaction/{{transaction_id}}/cancel');`}</span>
      <br />
      <span>{`$request->setMethod(HTTP_Request2::METHOD_GET);`}</span>
      <br />
      <span>{`$request->setConfig(array(`}</span>
      <br />
      <span
        style={{ paddingLeft: "0.5rem" }}
      >{`'follow_redirects' => TRUE`}</span>
      <br />
      <span>{`));`}</span>
      <br />
      <span>{`$request->setHeader(array(`}</span>
      <div style={{ marginLeft: "0.5rem" }}>
        <span>{`'Accept' => 'application/json',`}</span>
        <span>{`'apiKey' => 'TEST_KEY'`}</span>
        <br />

        <span>{`));`}</span>
      </div>
      <br />

      <p>
        <span>{`try {`}</span>
        <br />
        <p style={{ paddingLeft: "0.5rem" }}>
          <span>{`$response = $request->send();`}</span>
          <br />
          <span>{`if ($response->getStatus() == 200) {`}</span>
          <br />
          <span
            style={{ paddingLeft: "0.5rem" }}
          >{`echo $response->getBody();`}</span>
          <br />
          <span>{`}`}</span> <br />
          <span>{`else {`}</span>
          <br />
          <span
            style={{ paddingLeft: "0.5rem" }}
          >{`echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .`}</span>
          <br />
          <span
            style={{ paddingLeft: "0.5rem" }}
          >{`$response->getReasonPhrase();`}</span>
          <br />
          <span>{`}`}</span>
          <br />
          <span>{`}`}</span> <br />
          <span>{`catch(HTTP_Request2_Exception $e) {`}</span>
          <br />
          <span
            style={{ paddingLeft: "0.5rem" }}
          >{` echo 'Error: ' . $e->getMessage();`}</span>
          <br />
          <span>{`}`}</span> <br />
        </p>
      </p>
    </div>
  );
}

export default Php;
