import React from "react";
import PaymentSystemCard from "./PaymentSystemCard";
import styles from "./PaymentSystemCards.module.scss";
import { paymentSystemItems } from "../NavBar/variables";

function PaymentSystemCards() {
  return (
    <div className={styles.container}>
      {paymentSystemItems?.map((item) => (
        <PaymentSystemCard
          key={item?.key}
          item={item}
          options={paymentSystemItems}
          paymentSystemType={item?.value.toLowerCase()}
        />
      ))}
    </div>
  );
}

export default PaymentSystemCards;
