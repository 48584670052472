import React, { createContext, useEffect, useState } from "react";
import { useSession, useUser } from "@descope/react-sdk";
import PropTypes from "prop-types";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const [userType, setUserType] = useState(null);
  const isAdminOrStripe = userType === "admin" || userType === "stripe";

  useEffect(() => {
    if (user) {
      setUserType(user?.roleNames[0]?.toLowerCase());
    }
  }, [user]);

  const value = {
    userType,
    isAdminOrStripe,
    isAuthenticated,
    isSessionLoading,
    user,
    isUserLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
