import React, { useContext } from "react";
import SideMenuContext from "../../../context/SideMenuContext";
import Link from "./Link";
import styles from "./TextContent.module.scss";
import { paymentSystems } from "../../../utils/constants";

function BackendIntegration() {
  const { scroll } = useContext(SideMenuContext);

  return (
    <div className={styles.innerContentContainer}>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <p>
          <span className={styles.span}>
            Our backend integration includes{" "}
            <Link
              text={"REST API"}
              value={scroll.apiV1}
              setter={scroll.setApiV1}
            />{" "}
            and a set of optional callbacks (webhooks).
          </span>
        </p>
        <p>
          <span>
            Out of the{" "}
            <Link
              text={"API collection"}
              value={scroll.apiV1}
              setter={scroll.setApiV1}
            />{" "}
            , the only mandatory API endpoints to integrate with are:
          </span>
        </p>

        <p>
          <Link
            text={"POST Transaction"}
            value={scroll.transactionPostDecline}
            setter={scroll.setTransactionPostDecline}
            endpoint="transactionPostDecline"
          />

          <span>- to send declines transactions to Bounce</span>
        </p>
        <p>
          <Link
            text={"POST webhook event"}
            value={scroll.webhookEvent}
            setter={scroll.setWebhookEvent}
            endpoint={"webhookEvent"}
            paymentSystemType={paymentSystems.stripe}
          />

          <span className={styles.span}>
            - the endpoint that handles incoming webhook events from the payment
            system
          </span>
        </p>
      </div>

      {/*Supported Callbacks  */}
      <h2 className={styles.subTitle}>Supported Callbacks</h2>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <p className={styles.span}>
          In some flows, Bounce supports sending a callback to the merchant to
          inform on some events. These are the events on which Bounce can send
          the callback:
        </p>

        <ul className={styles.li}>
          <li>
            Retry attempt - Callback that informs the merchant that a new
            transaction was created by Bounce with the new tranaction ID and the
            ID of the originally declined transaction.
          </li>
          <li>
            <span>
              Auto decision - Callback that informs the merchant upon Bounce
              making a decision on a subscription renewal (i.e. a transaction
              that was not actively sent to Bounce with the{" "}
              <Link
                text={"decline API"}
                value={scroll.transactionPostDecline}
                setter={scroll.setTransactionPostDecline}
                endpoint="transactionPostDecline"
              />
              , and contains the transaction ID and the decision).
            </span>
          </li>
          <li>
            Abandoned transaction - Callback that informs the merchant that
            Bounce exhausted its retries for a specifc transaction, that
            contains the transaction ID.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BackendIntegration;
