import { routes } from "../../routes/routes";
import { paymentSystemSectionKey, paymentSystems } from "../../utils/constants";

function getItem(label, key, icon, children, className) {
  return {
    key,
    icon,
    children,
    label,
    className,
  };
}

const itemClassName = "side-menu-item";

export const items = [
  getItem("Introduction", "1", undefined, undefined, itemClassName),
  getItem("Supported Flows", "2", undefined, undefined, itemClassName),
  getItem("Backend Integration", "3", undefined, undefined, itemClassName),
  getItem("Front End Integration", "4", undefined, undefined, itemClassName),
  getItem(
    "API Endpoints",
    "sub-1",
    undefined,
    [
      getItem("Stripe", "5", undefined, undefined, itemClassName),
      getItem("Braintree", "6", undefined, undefined, itemClassName),
      getItem("PayPal", "7", undefined, undefined, itemClassName),
    ],
    itemClassName
  ),
];

export const sideMenuOnClick = (item, navigate) => {
  if (["1", "2", "3", "4"].includes(item?.key)) {
    navigate("/");
  } else if (item?.key === paymentSystemSectionKey.stripe) {
    navigate(routes.apiEndpoints.main(paymentSystems.stripe));
  } else if (item?.key === paymentSystemSectionKey.braintree) {
    navigate(routes.apiEndpoints.main(paymentSystems.braintree));
  } else if (item?.key === paymentSystemSectionKey.paypal) {
    navigate(routes.paypal.main);
  }
};
