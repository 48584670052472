import { Spin } from "antd";
import React from "react";
import styles from "./Spinner.module.scss";
import "./Spinner.css";

function Spinner() {
  return (
    <div className={styles.container}>
      <Spin
        size="large"
        className={styles.spinner}
        style={{ color: "black !important" }}
      />
    </div>
  );
}

export default Spinner;
