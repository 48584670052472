import React, { useContext } from "react";
import SideMenuContext from "../../../context/SideMenuContext";
import Link from "./Link";
import styles from "./TextContent.module.scss";

function Introduction() {
  const { scroll } = useContext(SideMenuContext);

  return (
    <div className={styles.textInnerContentContainer}>
      <div className={`${styles.paragraph} ${styles.mediumText}`}>
        <p>
          Bounce is a service that helps merchants save transactions that were
          declined by the merchant{"'"}s payment system.
        </p>
        <p>
          This is the API documentation for the merchant services provided by
          Bounce.
        </p>
        <p>
          In order for Bounce to provide its service, we require the following:
        </p>

        <ul className={styles.content_ul}>
          <li className={styles.il}>
            <span className={styles.span}>
              Integration with Bounce{"'"}s{" "}
              <Link
                text={"declined transaction endpoint"}
                value={scroll.transactionPostDecline}
                setter={scroll.setTransactionPostDecline}
                endpoint="transactionPostDecline"
              />
            </span>
          </li>
          <li>
            <span className={styles.span}>
              The merchant to place our JS snippet on their site, and call the
              connection event (See the{" "}
              <Link
                text={"Front End integration"}
                value={scroll.frontendIntegration}
                setter={scroll.setFrontendIntegration}
                section={true}
                sectionKey="4"
              />{" "}
              section).
            </span>
          </li>
          <li>
            <span className={styles.span}>
              Have access to all the transactions that are charged by the
              merchant, either through webhooks or by Bounce fetching them
              directly from the payment system.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Introduction;
