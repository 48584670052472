export const payloadOptions = [
  {
    key: 0,
    text: "PaymentIntent card error",
  },
  {
    key: 1,
    text: "Charge card error",
  },
  {
    key: 2,
    text: "Declined Subscription",
  },
];
