import React, { useContext, useEffect, useRef } from "react";
import NavContext from "../../../../context/NavContext";
import SideMenuContext from "../../../../context/SideMenuContext";
import { scrollToElement } from "../../../../utils/helperFunctions";
import HeadersAndParams from "../../../Shared/UrlExample/HeadersAndParams/HeadersAndParams";
import UrlExample from "../../../Shared/UrlExample/UrlExample";
import styles from "../../TextContent/TextContent.module.scss";
import AuthContext from "../../../../context/AuthContext";

function WebhookEvent() {
  const { isAdminOrStripe } = useContext(AuthContext);
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { apiOrSandbox } = useContext(NavContext);
  const myRef = useRef();

  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.webhookEvent,
      scroll.setWebhookEvent,
      toggleScroll
    );
  }, [scroll.webhookEvent]);
  return (
    <div ref={myRef} className={styles.transactionTextContainer}>
      <h2 className={styles.subTitle}>
        <span className={`${styles.orangeText} ${styles.titleSpace}`}>
          POST {""}
        </span>
        Webhook Event
      </h2>
      <UrlExample
        text={`https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/webhook/`}
      />
      <p className={`${styles.span} ${styles.mediumText}`}>
        This endpoint is used for payment systems that support webhooks. Bounce
        {"'"}s handling of the webhooks depends on the events that are supported
        by the payment system.
      </p>
      {isAdminOrStripe && <HeadersAndParams apiKey={true} />}
    </div>
  );
}

export default WebhookEvent;
