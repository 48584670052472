import React from "react";

function JavaUnirset() {
  return (
    <p>
      <span>{`Unirest.setTimeouts(0, 0);`}</span>
      <br />
      <span>{`HttpResponse<String> response = Unirest.post("https://{{merchant_name}}.bouncepay.io/api/v1/transaction/{{transaction_id}}/cancel")`}</span>
      <br />
      <div style={{ paddingLeft: "0.5rem" }}>
        <span>{`.header("Accept", "application/json")`}</span>
        <br />
        <span>{`.header("apiKey", "TEST_KEY")`}</span>
        <br />

        <span>{`.asString();`}</span>
        <br />
      </div>
    </p>
  );
}

export default JavaUnirset;
