import React, { useContext } from "react";
import NavContext from "../../../../../context/NavContext";

function Curl() {
  const { apiOrSandbox } = useContext(NavContext);
  return (
    <p>{`curl --location -g 'https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/api/v1/transaction/?skip=0&limit=10'`}</p>
  );
}

export default Curl;
