import React from "react";
import TextContent from "./TextContent";
import PaymentSystemCards from "../PaymentSystemCards";
import styles from "./Content.module.scss";

function Content() {
  return (
    <div className={styles.container}>
      <TextContent />
      <PaymentSystemCards />
    </div>
  );
}

export default Content;
